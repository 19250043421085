@import '@/styles/variables.module.scss';

.sliderMenu {
    &>div {
        padding-top: 20px;
    }

    &__navBtn {
        margin-top: auto;
        width: 100%;
        height: 25px;
        border-radius: 10px;
        position: static;
        background-color: $tech-bg;

        &:last-child {
            margin: 0;
        }

        &::after {
            display: none;
        }

        svg {
            width: 15px;
            height: 15px;
        }
    }

    &__slideLink {
        color: $black-color;
        transition: .2s ease;
        text-decoration: none;

        &:hover {
            color: $accent;
        }
    }
}