$black-color: #000;
$white: #fff;
$white-lilac: #f6f8fc;
$input-light: #EBF0F3;
$input-darker: #E4E9ED;
$background-white: #FEFEFE;
$accent: #FECB00;
$accent-hover: rgba(254, 203, 0, 0.4);
$accent-light: #FFEA99;
$darker: rgba(0, 0, 0, .15);
$bg-accent: #f6f8fc;
$hover-cat-menu: rgba(254, 203, 0, 0.28);
$tech-bg: #d9d9d9;
$dusty-gray: #9b9b9b;
$second-bg: #f7f7f7;
$gray: #f5f5f5;
$text-gray: #7D7D7D;
$gray-transparent: #696969;
$cornflower-blue: #7e7e7e;
$error: #f00;
$successfully: #45a882;
$graphite: #201f1f;

:export {
    error: $error;
    successfully: $successfully;
    blackColor: $black-color;
    whiteLilac: $white-lilac;
    inputLight: $input-light;
    inputDarker: $input-darker;
    backgroundWhite: $background-white;
    accent: $accent;
    darker: $darker;
    bgAccent: $bg-accent;
    textGray: $text-gray;
    accentLight: $accent-light;
    techBg: $tech-bg;
}