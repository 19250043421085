@import '@/styles/variables.module.scss';

.header {
    &Wrapper {
        background-color: $white-lilac;
    }

    display: grid;
    grid-template-columns: 70px 1fr 40px;
    align-items: center;
    gap: 20px;
    height: 60px;

    &__iconButton {
        justify-self: end;
    }

}