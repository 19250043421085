@import "@/styles/variables.module.scss";

.notification {
    padding: 1.2em 1.5em;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: $white-lilac;
    font-size: 0.9rem;

    &_danger {
        background-color: #fce2e2;
    }

    &_warning {
        background-color: #fecb003f;
    }

    &_success {
        background-color: #d0ffde;
    }

    a {
        color: $text-gray;
        text-decoration: none;
    }
}

@media(max-width: 1024px) {
    .notification {
        padding: 1em;
    }
}