@import '@/styles/variables.module.scss';

.overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0);
}

.overlayVisible {
    background-color: rgba(0, 0, 0, 0.5);
}

.categories {
    position: absolute;
    top: 140px;
    z-index: 11;
    display: flex;
    bottom: 0;
    border-radius: 0 0 10px 0;
    transition: .4s transform ease-in-out;
    transform: translateX(-105%);
    background-color: $bg-accent;

    &__list-wrapper {
        padding: 20px 40px;
        overflow-y: auto;
        transition: opacity .3s ease-in-out;
        background-color: $white-lilac;
        height: 500px;
    }

    &__list {
        margin: 0;
        padding-inline-start: 0;
        padding: 0;
        transition: opacity .3s ease-in-out;

        &-item {
            list-style: none;
        }
    }

    &__link {
        display: block;

        &-text {
            display: inline-flex;
            padding: 13px 10px;
            border-radius: 10px;
            transition: .1s background-color ease-in-out;
        }

        &:hover,
        &_active {
            .categories__link-text {
                background-color: $hover-cat-menu;
            }
        }
    }
}

.hidden {
    opacity: 0;
    display: none
}

.visible {
    opacity: 1;
    display: block;
}

.active {
    transform: translateX(0%);
}