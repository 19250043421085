.custom-swiper {
    .swiper-button-next::after {
        content: url('/images/swiper-arrow.svg') !important;
    }

    .swiper-button-prev::after {
        width: 40px;
        content: url('/images/swiper-arrow-prev.svg') !important;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0 !important;
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 40px !important;
        height: 40px !important;
        background: #f5f5f5;
        border-radius: 10px;
    }

    .swiper-button-next:active,
    .swiper-button-prev:active {
        transition: .1s opacity ease-in-out;
        opacity: 0.5;
    }
}