@import '@/styles/variables.module.scss';

.socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__list {
        display: flex;
        align-items: center;
        gap: 25px;
    }

    &__icons {
        display: flex;
        gap: 20px;
    }

    &__link {
        display: inline-flex;
        align-items: center;
        gap: 0.3em;
    }
}