@import "@/styles/variables.module.scss";

.mobile-cart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__content {
        margin-bottom: 20px;
    }

    &__buttons {
        margin-bottom: 20px;
    }
}