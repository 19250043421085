@import '@/styles/variables.module.scss';

.nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__list {
        display: flex;
        padding: 0;
        gap: 70px;
        justify-content: center;
    }
}

@media(max-width: 1200px) {
    .nav {
        &__list {
            gap: 0px 20px;
        }
    }
}

@media(max-width: 1024px) {
    .nav {
        &__list {
            gap: 20px;
            flex-wrap: wrap;
        }
    }
}