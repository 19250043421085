@import "@/styles/variables.module.scss";

.side-list {
    &__list {
        &-item {
            list-style: none;
        }
    }

    &__button {
        color: $black-color;
        text-decoration: none;
        font-size: 0.9em;
        padding: 0.7em 0.6em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        transition: .2s ease;
        border: none;
        background-color: transparent;
        width: 100%;
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: $white-lilac;
        }

        &_active,
        &_active:hover,
        &_active:focus {
            background-color: $accent-hover;

        }

        &-content {
            display: flex;
            align-items: center;
        }

        &-icon {
            margin-right: 0.5em;
        }

        &-arrow {
            justify-self: flex-end;
        }
    }
}

@media (max-width: 768px) {
    .side-list {
        &__button {

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }
    }
}