.footer {
    padding-top: 60px;
    padding-bottom: 60px;
    display: grid;
    grid-template-columns: 320px repeat(3, 1fr);
    gap: 80px;


    &__socials {
        justify-content: flex-start;

        & nav ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__nav {
        & nav ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 17px;
        }
    }


    &__menuSlider {
        white-space: nowrap;

        &>div {
            padding-top: 20px;
            max-height: 220px;
        }
    }
}

@media (max-width: 1220px) {
    .footer {
        grid-template-columns: repeat(4, 1fr);
        gap: 60px 30px;

    }
}

@media (max-width: 1024px) {
    .footer {
        grid-template-columns: repeat(2, 1fr);

    }
}

@media (max-width: 768px) {
    .footer {
        padding-top: 40px;
        padding-bottom: 100px;
    }
}

@media (max-width: 550px) {
    .footer {
        gap: 20px;
        grid-template-columns: 1fr;
    }
}