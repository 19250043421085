@import "@/styles/variables.module.scss";

.mini-cart {

    &__item {
        list-style: none;
        display: grid;
        grid-template-columns: 65px 1fr auto;
        gap: 15px;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        &-image-wrap {
            background-color: $white;
            width: 65px;
            height: 65px;
            padding: 5px;
            border-radius: 5px;
        }

        &-image {
            object-fit: contain;
            object-position: center;
        }

        &-name {
            color: $text-gray;
            margin-bottom: 6px;
        }

        &-delete {
            background-color: transparent;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            transition: .2s ease;

            &:hover {
                background-color: $input-darker;
            }
        }
    }

}