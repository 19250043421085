@import '@/styles/variables.module.scss';

.header {
    height: 65px;
    display: grid;
    grid-template-columns: 250px 1fr 250px;
    gap: 30px;
    align-items: center;
    justify-items: auto;

    &TopBarWrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: $white;
    }

    &Wrapper {
        background-color: $white-lilac;
    }

    &__icons {
        justify-self: end;
    }
}

@media (max-width: 1024px) {
    .header {
        grid-template-columns: 150px 1fr 150px;
    }
}