@import "@/styles/variables.module.scss";

.mobile-popup {
    position: fixed;
    z-index: 1000;
    background-color: $background-white;
    inset: 0;
    bottom: 70px;
    display: grid;
    grid-template-rows: 60px 1fr;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
    }

    &__close {
        display: block;
        padding: 10px;
        background-color: transparent;
        border: none;
    }

    &__content {
        padding: 0 20px;
        overflow: auto;
        position: relative;
    }
}