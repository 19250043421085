.topBar {
    &__stack {
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }
}

@media (max-width: 1024px) {
    .topBar {
        &__stack {
            padding-top: 15px;
            padding-bottom: 15px;
            height: auto;
        }
    }
}