@import './variables.module';

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.product-card-slider {

    .swiper-wrapper {
        padding: 5px 0;
    }

    .swiper-button-prev {
        left: -10px;
        color: $text-gray;
        transform: scaleX(0.25) scaleY(0.40);
        font-weight: bold;
    }

    .swiper-button-next {
        right: -10px;
        color: $text-gray;
        transform: scaleX(0.25) scaleY(0.40);
        font-weight: bold;
    }
}

.size-picks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
}

.size-pick {

    &__input {
        display: none;
    }

    &__island {
        font-size: 0.9rem;
        display: inline-flex;
        cursor: pointer;
        background-color: $input-light;
        padding: 0.3em 0.3em;
        border-radius: 5px;
        transition: .2s ease;

        &:hover {
            background-color: $input-darker;
        }

        input:disabled+& {
            opacity: 0.3;
            cursor: default;
        }

        input:checked+& {
            cursor: default;
            background-color: $white;
            outline: 1px solid $accent;
            box-shadow: $accent 0 0 5px;
            box-sizing: border-box;
        }
    }
}

.error {
    font-size: 14px;
    color: $error;

    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
}

.desc {
    font-weight: 400;
    color: $black-color;

    a {
        color: $text-gray;
        text-decoration: none;
        transition: .2s ease;

        &:hover {
            color: $accent;
        }
    }
}

.link {
    text-decoration: none;
    color: inherit;
}

.nav-link {
    transition: .2s color ease-in-out;
}

.nav-link:hover {
    color: $accent;
}

.nav-link:active {
    color: $black-color;
}

.nav-link:focus-visible {
    outline: 2px solid $accent;
    outline-offset: 10px;
}

.more-link {
    font-size: 18px;
    color: $text-gray;
    transition: color .3s ease-in-out;
}

.more-link:focus-visible {
    outline-color: $accent;
    outline-offset: 10px;
}

.more-link:hover {
    color: $black-color;
}

.more-link:active {
    color: $text-gray;
}

.main-title {
    font-family: "Inter", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    color: $black-color;
    font-size: 1.6rem;
}

.secondary-title {
    @extend .main-title;
    font-size: 1.1rem;
}

.sub-title {
    @extend .main-title;
    font-size: 20px;
    text-transform: none;

    &_builder {
        margin-bottom: 40px;
        text-transform: uppercase;
    }
}

.list-reset {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.date {
    color: $dusty-gray;
}

.btn {
    border-radius: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer
}

.btn-primary,
.btn-transparent {
    height: fit-content;
    padding: 13px 18px;
    border-radius: 10px;
    transition: background .2s ease-in-out;
}

.btn-primary {
    background: $accent;
}

.btn-primary:hover {
    background: $accent-hover;
}

.btn-primary:active {
    background: $accent;
}

.btn-transparent {
    border: 1px solid $black-color;
    background: transparent;
}

.btn-transparent:hover {
    background: $input-darker;
}

.btn-transparent:active {
    background: $gray;
}

.btn-secondary {
    background: transparent;
    padding: 12px 18px;
    border-radius: 10px;
    border: 1px solid $black-color;
    box-sizing: border-box;
    transition: .2s ease-in-out;
}

.btn-secondary:hover,
.btn-secondary.active {
    background: $accent-hover;
    border-color: transparent;
}

.cart-item-quty {

    &__x {
        margin: 0 0.3em;
    }

}

.product-page-h3 {
    font-size: 0.9em;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 1.6em;
}

.price {
    &__tax {
        color: $text-gray;
    }
}

.page-top {
    padding: 40px 0;

    &_center {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__titling {
        margin-top: 40px;
        display: flex;
        align-items: flex-start;
    }

    &__title {
        font-size: 1.15em;
        text-transform: uppercase;
        margin: 0;
        font-weight: 700;
    }

    &__count {
        margin-top: -1px;
        margin-left: 0.6em;
        background-color: $input-light;
    }
}

.text-center {
    text-align: center;
}

.text-gray {
    color: $text-gray;
}

.summary-wrapper {
    padding: 30px;
    padding-bottom: 30px;
    border: 2px solid $white-lilac;
    border-radius: 10px;
    // height: fit-content;
}

.html-text {

    line-height: 1.5;
    font-size: 0.9rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 1.4em;
        margin-bottom: 0.8em;
    }

    strong,
    b {
        font-weight: 600;
    }

    ul,
    ol {
        padding-left: 1.4em;
        margin-top: 0.8em;

        li {
            margin-bottom: 0.8em;
        }
    }
}

@media(max-width: 1024px) {
    .summary-wrapper {
        padding: 30px 35px;
    }

    .desc,
    .sub-title {
        font-size: 16px;
    }

    .more-link {
        font-size: 16px;
    }

    .page-top {
        padding: 10px 0;
        padding-bottom: 20px;

        &__titling {
            margin-top: 15px;
        }

    }
}


@media(max-width: 768px) {
    .summary-wrapper {
        padding: 20px 15px;
    }

    .desc,
    .sub-title {
        font-size: 14px;
    }

    .sub-title {
        &_builder {
            margin-bottom: 30px;
        }
    }

    .more-link {
        font-size: 14px;
    }

    .page-top {
        &__count {
            margin-top: -4px;
        }
    }
}