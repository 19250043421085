@import 'reset';
@import 'fonts';
@import 'globals';
@import './variables.module';
@import './swiperLib';

html,
body {
    font-size: 18px;
    font-family: "Inter", sans-serif;
    background-color: #fff;
    color: $black-color;
    margin: 0;
    padding: 0;
    line-height: 1.2;
}

body {
    padding-top: 150px;

    @media (max-width: 768px) {
        padding-top: 65px;
    }
}

#cookiescript_injected {
    font-size: 18px !important;
    font-family: "Inter", sans-serif !important;
}


* {
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: $tech-bg $background-white;
}

h1 {
    font-size: 1.1rem;
}

.container {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1360px;

    &_bread {
        padding-top: 40px;
    }
}

.section {
    padding-bottom: 100px;

    &_offset {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.slider {
    padding: 30px 0;
}

.features {
    padding-top: 20px;
    padding-bottom: 70px;
}

.tabs,
.topseller {
    padding: 50px;
    background-color: $white-lilac;
}

.product-price {
    margin-bottom: 0.8em;
    text-align: center;

    &-ending {
        color: $text-gray;
    }
}

.catalogs {
    margin-top: 50px;
}

.content-page {

    h1,
    h2,
    h3,
    h4 {
        margin-top: 1em;
        margin-bottom: .5em;

        &:first-child {
            margin-top: 0;
        }
    }

    p,
    li {
        margin-bottom: 10px;
        line-height: 1.4em;
    }

    ul,
    ol {
        margin-bottom: 20px;
        padding-left: 40px;
    }

    figure {
        padding: 50px;
        text-align: center;

        img {
            max-width: 100%;
        }
    }
}

@media(max-width: 1024px) {
    .container {
        padding: 0 20px;

        &_bread {
            padding-top: 30px;
        }
    }

    .section {
        padding-bottom: 80px;

        &_offset {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    .features {
        padding-bottom: 40px;
    }

    .catalogs {
        margin-top: 30px;
    }

    .tabs,
    .topseller {
        padding: 10px;
    }
}

@media(max-width: 768px) {

    html,
    body {
        font-size: 14px;
    }

    .section {
        padding-bottom: 40px;

        &_offset {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .slider {
        padding: 15px 0;
    }

    .features {
        padding-top: 10px;
        padding-bottom: 20px;
    }
}