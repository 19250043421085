@import "@/styles/variables.module.scss";

.totals-table {
    font-size: 0.9rem;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    &_borders {
        padding: 0.5em 0;
        border-radius: 10px;
        border: 2px solid $white-lilac;

        .totals-table__row {
            padding-left: 1.5em;
            padding-right: 1.5em;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        padding-top: 0.6em;

        &:last-child {
            margin-top: 0.6em;
            padding-top: 1em;
            padding-bottom: 0.6em;
            border-top: 2px solid $white-lilac;
            margin-bottom: 0;
            font-weight: 500;
            font-size: 1.2em;
        }
    }

    &__label {
        color: $text-gray;
        width: 170px;

        &-code {
            background-color: $input-light;
            padding: 0.2em 0.4em;
            display: inline-block;
            margin-top: 0.2em;
            border-radius: 6px;
        }
    }

    &__value {
        text-align: right;
    }
}

@media(max-width: 1024px) {
    .totals-table {
        &_borders {

            .totals-table__row {
                padding-left: 1em;
                padding-right: 1em;
            }
        }

        &__row {
            padding-top: 0.5em;
            padding-top: 0.5em;

            &:last-child {
                margin-top: 0.5em;
                padding-bottom: 0.5em;
            }
        }
    }
}